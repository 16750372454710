import {
  Box,
  Anchor,
  Tip,
  Text,
} from "grommet";
import { Avatar } from "@/cloverleaf-ui/components";
import { DROP_POSITION } from "@/cloverleaf-ui/constants";

const SIZE_MAP = {
  small: {
    width: "24px",
    avatarSize: "xsmall",
    left: "18",
    textSize: "small",
  },
  large: {
    width: "40px",
    avatarSize: "40px",
    left: "30",
    textSize: "large",
  },
}

function StackedAvatars({ users, onClick, size = "large", maxUsers = 7 }) {
  if (!Object.keys(SIZE_MAP).includes(size)) {
    console.error(`Invalid size: ${size}`);

    return null;
  }

  return (
    <Box
      direction="row"
      style={{ alignItems: "center", position: "relative" }}
    >
      {users?.slice(0, (maxUsers + 1)).map((user, index) => {
        if (index < maxUsers) {
          if (!user.profile?.relativePublicUrl) {
            return (
              <Box
                key={user.id}
                style={{ position: "absolute", left: `${index * SIZE_MAP[size].left}px`, zIndex: maxUsers - index }}
              >
                <Tip
                  content={user.fullName}
                  dropProps={{ align: DROP_POSITION.top }}
                >
                  <Avatar
                    user={user}
                    size={SIZE_MAP[size].avatarSize}
                  />
                </Tip>
              </Box>
            )
          }

          return (
            <Anchor
              href={user.profile.relativePublicUrl}
              key={user.id}
              onClick={onClick}
              style={{
                position: "absolute",
                left: `${index * SIZE_MAP[size].left}px`,
                zIndex: maxUsers - index,
                textDecoration: "none",
              }}
            >
              <Tip content={user.fullName} dropProps={{ align: DROP_POSITION.top }}>
                <Avatar user={user} size={SIZE_MAP[size].avatarSize} />
              </Tip>
            </Anchor>
          )
        }

        return (
          <Anchor
            key={user.id}
            style={{ position: "absolute", left: `${index * SIZE_MAP[size].left}px` }}
          >
            <Tip
              content={
                <Box
                  gap="small"
                  overflow="auto"
                  flex={false}
                  style={{ maxHeight: "300px" }}
                  pad={{ vertical: "xsmall", right: "xsmall" }}
                >
                  {users.slice(maxUsers).map((avatar) => (
                    <Box
                      key={avatar.id}
                      align="center"
                      pad={{ vertical: "xsmall" }}
                      direction="row"
                      gap="xsmall"
                    >
                      <Avatar user={avatar} size="xsmall" />
                      <Text style={{ whiteSpace: "nowrap" }}>{avatar.fullName}</Text>
                    </Box>
                  ))}
                </Box>
              }
              dropProps={{ align: DROP_POSITION.bottom }}
            >
              <Box align="center" background="grey600" height={SIZE_MAP[size].width} justify="center" round="full" width={SIZE_MAP[size].width}>
                <Text size={SIZE_MAP[size].textSize}>{`+${users.slice(maxUsers).length}`}</Text>
              </Box>
            </Tip>
          </Anchor>
        )
      })}
    </Box>
  )
}

export { StackedAvatars };
