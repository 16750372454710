import { Button } from "grommet";
import { Icon, TipContent } from "@/cloverleaf-ui/components";
import { DROP_POSITION } from "@/cloverleaf-ui/constants";
import { useLocale } from "@/lib/hooks";

function ConversationCardsButton() {
  const { t } = useLocale();

  return (
    <Button
      plain
      onClick={() => {
        return window.open("/conversation-cards", "_blank", "width=700,height=850");
      }}
      icon={(
        <Icon
          size="lg"
          icon={["fad", "cards-blank"]}
          style={{
            ["--fa-primary-color"]: "#00853D",
            ["--fa-secondary-color"]: "#5F008C",
          }}
        />
      )}
      tip={{
        content: <TipContent message={t("conversation-cards")} position="left" />,
        plain: true,
        dropProps: {
          align: DROP_POSITION.left,
        },
      }}
    />
  );
}

export { ConversationCardsButton };
